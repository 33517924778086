var Launcher = {

    LAUNCHER_COMMON_CONTROLLER: 'Common',
    LAUNCHER_INIT_ACTION: 'init',

    exec: function( controller, action ) {
        var ns = App;
        var _action = (typeof action === 'undefined') ? this.LAUNCHER_INIT_ACTION : action;

        console.log('Load: ' + controller + '.' + _action);

        if(typeof controller !== 'undefined') {
            if (typeof ns[_action] == "function") {
                ns[_action]();
            }

            if (ns[controller] && typeof ns[controller][_action] == "function") {
                ns[controller][_action]();
            }
        }
    },

    init: function() {
        var body = document.body,
            controller = body.getAttribute( "data-controller" ),
            action = body.getAttribute( "data-action" );

        this.exec(this.LAUNCHER_COMMON_CONTROLLER);
        this.exec(controller);
        this.exec(controller, action);
    }
};

$(document).ready(function() {
    Launcher.init();
});
