App = {

    /**
     * show or hide console messages
     */
    DEBUG: true,

    /**
     * Debug function
     * @param data
     * @param debug
     */
    debug: function(data, debug) {
        if(debug === undefined && App.DEBUG) {
            console.log(data);
        }
    }
};

App.Auth = {
	showLoginForm: function() {
		$('body').removeClass('sw-toggled');
	}
};
