App.Index = {
	init: function() {
	},
	index: function() {
		if(Cookies.get('session-id') === undefined) {
			Cookies.set('session-id', App.Index.guid());
		}
		App.Index.getQuestions();

		$(document).on('submit', '#form-question', function (evt) {
			evt.preventDefault();

			var $this = $(this);
			$.ajax({
				url: $this.attr('action'),
				type: 'POST',
				dataType: 'json',
				data: {
					question: $this.find('[name="question"]').val(),
					uuid: Cookies.get('session-id')
				}
			}).done(function(response) {
				if(response.success) {
					App.Index.getQuestions();
					$this.find('[name="question"]').val('');
					App.Common.notify('Domanda inviata con successo. Grazie!', 'success');
				}
			});
		});
	},
	admin: function () {
		App.Index.getQuestionsTable(1);
		App.Common.pagination('Index', 'getQuestionsTable');
		$(document).on('click', '.question-state a', function (evt) {
			evt.preventDefault();
			var $this = $(this);
			$.ajax({
				url: $this.attr('href'),
				type: 'GET',
				dataType: 'json',
			}).done(function(response) {
				if(response.success) {
					App.Index.getQuestionsTable(App.Common.getCurrentPage());
				}
			});
		});
	},
	getQuestionsTable: function (page) {
		$.ajax({
			url: BASE_URL + '/admin/get-questions-table?page=' + page,
			type: 'POST',
			dataType: 'json',
		}).done(function(response) {
			$('#questions-tbody').html(response.data.tbody);
			$('#pagination').html(response.data.pagination);
		});
	},
	getQuestions: function () {
		$.ajax({
			url: BASE_URL + '/get-questions',
			type: 'POST',
			dataType: 'json',
			data: {
				uuid: Cookies.get('session-id')
			}
		}).done(function(response) {
			$('#questions').html(response.data);
		});
	},
	guid: function() {
		return App.Index.s4() + App.Index.s4() + '-' + App.Index.s4() + '-' + App.Index.s4() + '-' +
			App.Index.s4() + '-' + App.Index.s4() + App.Index.s4() + App.Index.s4();
	},
	s4: function () {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
			;
	}
};
