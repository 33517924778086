App.Common = {

    init: function(){
		App.debug('Application.Common.init loaded');

        this._jQuerySetup();
        this._ajaxSetup();
        this.initSwitch();
        this.initSelectpicker();
        this.initOwlCarousel();
        this.toggleEnabled();
    },

    /**
     *
     * setup for jQuery
     *
     */
    _jQuerySetup: function() {
        /**
         * custom event for detect input fields changes
         *
         * @type {{setup: $.event.special.inputchange.setup, teardown: $.event.special.inputchange.teardown, add: $.event.special.inputchange.add}}
         */
        $.event.special.inputchange = {
            setup: function() {
                var self = this, val;
                $.data(this, 'timer', window.setInterval(function() {
                    val = self.value;
                    if ( $.data( self, 'cache') != val ) {
                        $.data( self, 'cache', val );
                        $( self ).trigger( 'inputchange' );
                    }
                }, 500));
            },
            teardown: function() {
                window.clearInterval( $.data(this, 'timer') );
            },
            add: function() {
                $.data(this, 'cache', this.value);
            }
        };
    },
    _ajaxSetup: function()
    {
        $.ajaxSetup({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
        });
    },
    initSwitch: function() {
        if (jQuery().bootstrapSwitch) {
            $('input.switch').bootstrapSwitch({
                onText: 'Sì',
                offText: 'No',
                onSwitchChange: function(event, state) {
                    if(state == true) {
                        $(this).attr('checked', '');
                        $(this).val(1);
                    } else {
                        $(this).removeAttr('checked');
                        $(this).val(0);
                    }
                }
            });
        }
    },
    initSelectpicker: function() {
        if (jQuery().selectpicker)
		{
            $('.select-picker').selectpicker({
                noneSelectedText: 'Nulla selezionato'
            });
        }
    },
    initOwlCarousel: function() {
		$('.owl-carousel').owlCarousel({
			loop: true,
			margin: 10,
			nav: true,
			navClass: ['fa fa-arrow-left fa-2x cursor-pointer', 'fa fa-arrow-right fa-2x cursor-pointer pull-right'],
			navText: ['', '']
		})
    },
	toggleEnabled: function()
	{
		$('body').on('click', '.toggle-enabled', function(evt)
		{
			evt.preventDefault();
			var $item = $(this);
			if($item.attr('data-confirm'))
			{
				var message = $item.attr('data-confirm');
			}else{
				var message = $item.hasClass('btn-success') ? 'Confermi la disabilitazione?' : 'Conferma l\'abilitazione?'
			}
			swal({
				title: message,
				type: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sì',
				cancelButtonText: 'Annulla'
			}).then(function(isConfirm)
			{
				if(isConfirm !== false)
				{
					$.ajax({
						url: $item.attr('href'),
						type: 'POST',
						dataType: 'json'
					}).done(function(response)
					{
						if(response && response.status == 'success')
						{
							if(response.data.enabled)
							{
								message = "Abilitato con successo";
							}else{
								message = "Disabilitato con successo";
							}
							swal(
								'Modificato!',
								message,
								'success'
							);
							$item.closest('.row').html(response.data.body);
						}else{
							swal(
								'Oops!',
								response.message,
								'error'
							);
						}
					});
				}
			});
		})
	},

	/*
	 * Bootstrap Growl - Notifications popups
	 */
	notify: function(message, type)
	{
		$.growl({
			message: message
		},{
			type: type,
			allow_dismiss: false,
			label: 'Cancel',
			className: 'btn-xs btn-inverse',
			placement: {
				from: 'top',
				align: 'center'
			},
			delay: 2500,
			animate: {
				enter: 'animated bounceIn',
				exit: 'animated bounceOut'
			},
			offset: {
				x: 20,
				y: 85
			}
		});
	},
	showLoader: function()
	{
		if(!$('html').hasClass('ismobile'))
		{
			$('.page-loader').fadeIn();
		}
	},
	hideLoader: function()
	{
		if(!$('html').hasClass('ismobile'))
		{
			$('.page-loader').fadeOut();
		}
	},
	setActiveMenu: function(id)
	{
		$(id).addClass('active');
	},
	initAutoSize: function () {
		var element = $('.auto-size');
		autosize(element);
		autosize.update(element);
	},
	getCurrentPage: function(){
		return $('#pagination').find('li.active').find('span').html();
	},
	pagination: function(controller, action) {
		$('body').on('click', '#pagination a', function(evt){
			evt.preventDefault();
			var $item = $(this);

			var rel = $item.attr('rel'),
				page = $item.html()
			;
			if(rel) {
				page = App.Common.getCurrentPage();
				if(rel == 'next')
				{
					page ++;
				}else{
					page --;
				}
			}

			App[controller][action](page);
		});
	}
};
